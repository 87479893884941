<template>
  <div v-if="initialLoading">
    <v-row style="margin-top: 24px">
      <v-col cols="6">
        <v-skeleton-loader type="heading"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-skeleton-loader type="table"></v-skeleton-loader>
      </v-col>
    </v-row>
  </div>
  <DatatableWrapper
    v-else
    :loading="usersLoading"
    :pagination="pagination"
    clickable-row
    title="Session Users"
  >
    <v-data-table
      :expanded.sync="latestExpand"
      :headers="userHeader"
      :items="sessionUsers"
      :loading="usersLoading"
      disable-sort
      hide-default-footer
      single-expand
      @click:row="expendLatestRow"
    >
      <template #item.id="{ item }">
        <span class="cursor-pointer text-capitalize">
          {{ item.id }}
        </span>
      </template>
      <template #item.name="{ item }">
        <v-row align="center" class="py-3">
          <v-col cols="auto">
            <ProfileImage
              :alt="item?.name || 'A'"
              :image="item?.image"
            ></ProfileImage>
          </v-col>
          <v-col>
            <div
              :title="item?.name || 'Anonymous'"
              class="regular-font font14 truncate"
              style="max-width: 380px"
            >
              {{ item?.name || "Anonymous" }}
            </div>
            <div class="primary--text font14">
              {{ item?.email || item.anonymous_id }}
            </div>
          </v-col>
        </v-row>
      </template>
      <template #item.email="{ item }">
        {{ item.email }}
      </template>
      <template #item.session="{ item }">
        <v-btn
          class="primary--text"
          icon
          @click.stop="
            $router.push({
              name: 'app-sessions',
              query: { user_id: item.id },
            })
          "
        >
          {{ item?.stats?.sessions ?? 0 | formatNumber }}
        </v-btn>
      </template>
      <template #item.devices="{ item }">
        <v-btn
          class="primary--text"
          icon
          @click.stop="
            $router.push({
              name: 'app-sessions-devices',
              query: { user_id: item.id },
            })
          "
          >{{ item?.stats?.devices ?? 0 | formatNumber }}
        </v-btn>
      </template>
      <template #item.feedback="{ item }">
        <v-btn
          class="primary--text"
          icon
          @click.stop="
            $router.push({
              name: 'app-sessions-feedback',
              query: { user_id: item.id },
            })
          "
        >
          {{ item?.stats?.feedback ?? 0 | formatNumber }}
        </v-btn>
      </template>
      <template #item.apps="{ item }">
        <v-btn
          class="primary--text"
          icon
          @click.stop="
            $router.push({
              name: 'app-sessions-applications',
              query: { user_id: item.id },
            })
          "
        >
          {{ item?.stats?.apps ?? 0 | formatNumber }}
        </v-btn>
      </template>

      <template #expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pa-0">
          <v-card class="pa-4" flat tile>
            <v-row>
              <v-col v-if="!isEmpty(item?.traits)" cols="12">
                <div class="mb-3">Attributes</div>
                <div
                  v-for="(trait, index) in Object.keys(item?.traits)"
                  :key="index"
                >
                  {{ trait }}:
                  <span>
                    {{ item?.traits[trait] }}
                  </span>
                </div>
              </v-col>
              <v-col v-else> No traits available</v-col>
            </v-row>
          </v-card>
        </td>
      </template>
    </v-data-table>
  </DatatableWrapper>
</template>

<script>
import { isEmpty } from "@/core/services/helper.service";
import DatatableWrapper from "@/view/components/App/DatatableWrapper";

export default {
  data() {
    return {
      isEmpty,
      pagination: {
        page: this.toNumber(this.$route.query.page),
        itemsPerPage: 10,
        totalItems: 0,
      },
      userHeader: [
        {
          text: "ID",
          value: "id",
          width: 0,
        },
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Sessions",
          value: "session",
          align: "center",
          width: 100,
        },
        {
          text: "Devices",
          value: "devices",
          align: "center",
          width: 100,
        },
        {
          text: "Feedback",
          value: "feedback",
          align: "center",
          width: 100,
        },
        {
          text: "Applications",
          value: "apps",
          align: "center",
          width: 100,
        },
      ],
      sessionUsers: [],
      latestExpand: [],
      usersLoading: true,
      totalUserCount: 0,
      initialLoading: true,
    };
  },
  components: { DatatableWrapper },
  computed: {
    lastId() {
      return this.sessionUsers[this.sessionUsers.length - 1]?.id || "";
    },
    appId() {
      return this.$route.params.app_id;
    },
  },
  watch: {
    async "pagination.page"(newPage) {
      await this.$router.push({
        query: { ...this.$route.query, page: newPage },
      });

      this.pagination.page = newPage;
      this.getSessionUsers({ last_id: this.lastId, page: newPage });
    },
    totalUserCount(val) {
      this.pagination.totalItems = val;
    },
  },
  created() {
    this.getSessionUsers().then(() => {
      this.initialLoading = false;
    });
  },
  methods: {
    getSessionUsers({ last_id = "", page = this.pagination.page } = {}) {
      this.usersLoading = true;
      const params = {
        last_id,
        app_id: this.appId,
        device_id: this.$route.query.device_id,
        page,
      };

      return this.$coreApi
        .get(`${this.apiRoutes.core.session.allUsers}?${this.getQuery(params)}`)
        .then(({ data }) => {
          this.sessionUsers = data.data;
          this.totalUserCount = data.meta.total || 0;
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
        })
        .then(() => {
          this.usersLoading = false;
        });
    },
    expendLatestRow(item) {
      if (!item.value) {
        this.latestExpand = item === this.latestExpand[0] ? [] : [item];
      }
    },
  },
};
</script>
