var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.initialLoading ? _c('div', [_c('v-row', {
    staticStyle: {
      "margin-top": "24px"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "heading"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-skeleton-loader', {
    attrs: {
      "type": "table"
    }
  })], 1)], 1)], 1) : _c('DatatableWrapper', {
    attrs: {
      "loading": _vm.usersLoading,
      "pagination": _vm.pagination,
      "clickable-row": "",
      "title": "Session Users"
    }
  }, [_c('v-data-table', {
    attrs: {
      "expanded": _vm.latestExpand,
      "headers": _vm.userHeader,
      "items": _vm.sessionUsers,
      "loading": _vm.usersLoading,
      "disable-sort": "",
      "hide-default-footer": "",
      "single-expand": ""
    },
    on: {
      "update:expanded": function updateExpanded($event) {
        _vm.latestExpand = $event;
      },
      "click:row": _vm.expendLatestRow
    },
    scopedSlots: _vm._u([{
      key: "item.id",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('span', {
          staticClass: "cursor-pointer text-capitalize"
        }, [_vm._v(" " + _vm._s(item.id) + " ")])];
      }
    }, {
      key: "item.name",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('v-row', {
          staticClass: "py-3",
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('ProfileImage', {
          attrs: {
            "alt": (item === null || item === void 0 ? void 0 : item.name) || 'A',
            "image": item === null || item === void 0 ? void 0 : item.image
          }
        })], 1), _c('v-col', [_c('div', {
          staticClass: "regular-font font14 truncate",
          staticStyle: {
            "max-width": "380px"
          },
          attrs: {
            "title": (item === null || item === void 0 ? void 0 : item.name) || 'Anonymous'
          }
        }, [_vm._v(" " + _vm._s((item === null || item === void 0 ? void 0 : item.name) || "Anonymous") + " ")]), _c('div', {
          staticClass: "primary--text font14"
        }, [_vm._v(" " + _vm._s((item === null || item === void 0 ? void 0 : item.email) || item.anonymous_id) + " ")])])], 1)];
      }
    }, {
      key: "item.email",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.email) + " ")];
      }
    }, {
      key: "item.session",
      fn: function fn(_ref4) {
        var _item$stats$sessions, _item$stats;
        var item = _ref4.item;
        return [_c('v-btn', {
          staticClass: "primary--text",
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.$router.push({
                name: 'app-sessions',
                query: {
                  user_id: item.id
                }
              });
            }
          }
        }, [_vm._v(" " + _vm._s(_vm._f("formatNumber")((_item$stats$sessions = item === null || item === void 0 ? void 0 : (_item$stats = item.stats) === null || _item$stats === void 0 ? void 0 : _item$stats.sessions) !== null && _item$stats$sessions !== void 0 ? _item$stats$sessions : 0)) + " ")])];
      }
    }, {
      key: "item.devices",
      fn: function fn(_ref5) {
        var _item$stats$devices, _item$stats2;
        var item = _ref5.item;
        return [_c('v-btn', {
          staticClass: "primary--text",
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.$router.push({
                name: 'app-sessions-devices',
                query: {
                  user_id: item.id
                }
              });
            }
          }
        }, [_vm._v(_vm._s(_vm._f("formatNumber")((_item$stats$devices = item === null || item === void 0 ? void 0 : (_item$stats2 = item.stats) === null || _item$stats2 === void 0 ? void 0 : _item$stats2.devices) !== null && _item$stats$devices !== void 0 ? _item$stats$devices : 0)) + " ")])];
      }
    }, {
      key: "item.feedback",
      fn: function fn(_ref6) {
        var _item$stats$feedback, _item$stats3;
        var item = _ref6.item;
        return [_c('v-btn', {
          staticClass: "primary--text",
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.$router.push({
                name: 'app-sessions-feedback',
                query: {
                  user_id: item.id
                }
              });
            }
          }
        }, [_vm._v(" " + _vm._s(_vm._f("formatNumber")((_item$stats$feedback = item === null || item === void 0 ? void 0 : (_item$stats3 = item.stats) === null || _item$stats3 === void 0 ? void 0 : _item$stats3.feedback) !== null && _item$stats$feedback !== void 0 ? _item$stats$feedback : 0)) + " ")])];
      }
    }, {
      key: "item.apps",
      fn: function fn(_ref7) {
        var _item$stats$apps, _item$stats4;
        var item = _ref7.item;
        return [_c('v-btn', {
          staticClass: "primary--text",
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.$router.push({
                name: 'app-sessions-applications',
                query: {
                  user_id: item.id
                }
              });
            }
          }
        }, [_vm._v(" " + _vm._s(_vm._f("formatNumber")((_item$stats$apps = item === null || item === void 0 ? void 0 : (_item$stats4 = item.stats) === null || _item$stats4 === void 0 ? void 0 : _item$stats4.apps) !== null && _item$stats$apps !== void 0 ? _item$stats$apps : 0)) + " ")])];
      }
    }, {
      key: "expanded-item",
      fn: function fn(_ref8) {
        var headers = _ref8.headers,
          item = _ref8.item;
        return [_c('td', {
          staticClass: "pa-0",
          attrs: {
            "colspan": headers.length
          }
        }, [_c('v-card', {
          staticClass: "pa-4",
          attrs: {
            "flat": "",
            "tile": ""
          }
        }, [_c('v-row', [!_vm.isEmpty(item === null || item === void 0 ? void 0 : item.traits) ? _c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('div', {
          staticClass: "mb-3"
        }, [_vm._v("Attributes")]), _vm._l(Object.keys(item === null || item === void 0 ? void 0 : item.traits), function (trait, index) {
          return _c('div', {
            key: index
          }, [_vm._v(" " + _vm._s(trait) + ": "), _c('span', [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.traits[trait]) + " ")])]);
        })], 2) : _c('v-col', [_vm._v(" No traits available")])], 1)], 1)], 1)];
      }
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }